<template>

  <q-page
    class="window-height window-width row justify-center items-center bg-primary">

    <AuthCardForm @submit="login">

      <BaseInput
        color="primary"
        dark
        label="Email"
        clearable
        clear-icon="mdi-close"
        type="email"
        no-error-icon
        error-name="email"
        :errors="errors"
        :stack-label="false"
        v-model="email"
        :rules="[val => !!val || 'Campo Obbligatorio']">

        <template #prepend>
          <q-icon name="mdi-email" />
        </template>
      </BaseInput>

      <BaseInput
        color="primary"
        label="Password"
        dark
        clearable
        clear-icon="mdi-close"
        no-error-icon
        error-name="password"
        :errors="errors"
        :stack-label="false"
        v-model="password"
        :type="showPassword ? 'text' : 'password'"
        :rules="[val => !!val || 'Campo Obbligatorio']">

        <template #prepend>
          <q-icon name="mdi-lock" />
        </template>
        <template #append>
          <q-icon
            :name="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            class="cursor-pointer"
            @click="togglePassword"
          />
        </template>
      </BaseInput>

      <div class="row justify-end">

        <BaseBtn
          type="submit"
          :disabled="loading"
          label="Accedi"
          class="q-mt-sm full-width q-pa-sm"
          color="primary" />

      </div>

      <div class="row justify-end">

        <BaseBtn
          flat
          no-caps
          @click="forgotPassword"
          label="Hai dimenticato la password?"
          class="full-width"
          color="primary" />

      </div>

    </AuthCardForm>

    <BaseInnerLoading :showing="loading" />

  </q-page>

</template>

<script>
import AuthCardForm from '../components/auth/AuthCardForm.vue'
import useApiErrors from '../hooks/apiErrors.js'
import useAuth from '../hooks/auth.js'
import useToggler from '../hooks/toggler.js'
import { ref } from '@vue/composition-api'
import { router } from '../config/router.js'

export default{
  name: 'Login',

  components:{
    AuthCardForm,
  },

  setup(){
    const { toggler : showPassword, toggle: togglePassword } = useToggler()
    const { toggler : loading, on: showLoader, off: hideLoader } = useToggler()
    const { login, forgotPassword } = useAuth()

    const errors = useApiErrors()

    const email = ref('')
    const password = ref('')

    return {
      errors,
      loading,
      showPassword,
      togglePassword,
      //login data
      email,
      password,
      forgotPassword,
      login : () => {
        showLoader()
        login( email.value, password.value ).then( () => {
          router.push({ name : 'home' })
        })
          .catch( errors.setError )
          .finally( hideLoader )
      },
    }
  }
}
</script>

